import { useQuery } from '@apollo/client'
import { Flex, Skeleton } from '@chaine/keychaine'
import { uniqueId } from 'lodash'
import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'

import { COMPANY_INTELLIGENCE } from '../../../booking/data'
import { TAssociationField, TFMCSAChangesField } from '../../../booking/types'
import { DataContext } from '../../index'
import CompanyPageLayout, { CompanySectionLayout } from '../layout'
import { AssessmentCard, AuthorityCard, DetailsCard, IntelligenceCard } from '../overview'

/**
 * This tab includes basic information about company
 * for example - authority details, assessment details and its personal details like telephone, email, address
 */
export function OverviewTabPanel() {
  const { companyData, isCompanyDataLoading } = useContext(DataContext)
  const { dotNumber } = useParams()
  const { data, loading } = useQuery(COMPANY_INTELLIGENCE, {
    variables: {
      dotNumber: dotNumber
    }
  })

  return (
    <React.Fragment>
      <CompanyPageLayout
        leftChildren={
          <CompanySectionLayout gap={8}>
            <IntelligenceCard
              contents={[
                {
                  heading: 'FMCSA Census Changes (past 6 months)',
                  items:
                    isCompanyDataLoading || loading
                      ? [
                          <Skeleton height={6} width="60%" borderRadius="base" key={uniqueId()} />,
                          <Skeleton
                            height={6}
                            width="60%"
                            marginTop={2}
                            marginBottom={2}
                            borderRadius="base"
                            key={uniqueId()}
                          />,
                          <Skeleton height={6} width="60%" marginBottom={0} borderRadius="base" key={uniqueId()} />
                        ]
                      : data?.companyIntelligence?.fmcsaChanges?.length > 0
                      ? data?.companyIntelligence?.fmcsaChanges?.map((item: TFMCSAChangesField) => item.alert)
                      : []
                },
                {
                  heading: 'Associations',
                  items:
                    isCompanyDataLoading || loading
                      ? [
                          <Skeleton height={6} width="60%" borderRadius="base" key={uniqueId()} />,
                          <Skeleton
                            height={6}
                            width="60%"
                            marginTop={2}
                            marginBottom={2}
                            borderRadius="base"
                            key={uniqueId()}
                          />,
                          <Skeleton height={6} width="60%" marginBottom={0} borderRadius="base" key={uniqueId()} />
                        ]
                      : data?.companyIntelligence?.associations?.length > 0
                      ? data?.companyIntelligence?.associations?.map((item: TAssociationField) => item.alert)
                      : []
                }
              ]}
            />
            <DetailsCard
              contents={[
                {
                  content:
                    isCompanyDataLoading || loading ? (
                      <Skeleton height={6} marginTop={1} marginBottom={1} width="70%" />
                    ) : (
                      `+1 ${companyData?.telephone?.oldestValue?.value}`
                    ),
                  heading: 'Telephone'
                },
                {
                  content:
                    isCompanyDataLoading || loading ? (
                      <Skeleton height={6} marginTop={1} marginBottom={1} width="70%" />
                    ) : (
                      companyData?.emailAddress?.oldestValue?.value?.toLocaleLowerCase()
                    ),
                  heading: 'Email Address'
                },
                {
                  content:
                    isCompanyDataLoading || loading ? (
                      <Flex direction="column" gap={1}>
                        {new Array(3).fill(0).map(() => (
                          <Skeleton height={6} width="70%" borderRadius="base" key={uniqueId()} />
                        ))}
                      </Flex>
                    ) : (
                      <>
                        {companyData?.mailingStreet?.oldestValue?.value} <br />{' '}
                        {companyData?.mailingCity?.oldestValue?.value} <br />{' '}
                        {companyData?.mailingState?.oldestValue?.value},{' '}
                        {companyData?.mailingCountry?.oldestValue?.value} {companyData?.mailingZip?.oldestValue?.value}{' '}
                        <br />
                        United States
                      </>
                    ),
                  heading: 'Mailing Address'
                },
                {
                  content:
                    isCompanyDataLoading || loading ? (
                      <Flex direction="column" gap={1}>
                        {new Array(3).fill(0).map(() => (
                          <Skeleton height={6} width="70%" borderRadius="base" key={uniqueId()} />
                        ))}
                      </Flex>
                    ) : (
                      <>
                        {companyData?.physicalStreet?.oldestValue?.value} <br />{' '}
                        {companyData?.physicalCity?.oldestValue?.value} <br />{' '}
                        {companyData?.physicalState?.oldestValue?.value},{' '}
                        {companyData?.physicalCountry?.oldestValue?.value}{' '}
                        {companyData?.physicalZip?.oldestValue?.value} <br />
                        United States
                      </>
                    ),
                  heading: 'Physical Address'
                }
              ]}
            />
          </CompanySectionLayout>
        }
        rightChildren={
          isCompanyDataLoading || loading ? (
            <Flex direction="column" gap={4} marginTop={2}>
              {new Array(3).fill(0).map(() => (
                <Skeleton height={20} marginTop={1} marginBottom={1} borderRadius="base" key={uniqueId()} />
              ))}
            </Flex>
          ) : (
            <CompanySectionLayout gap={8}>
              {companyData?.carrierAuthority?.oldestValue?.value && (
                // Below code commented as we will use it in future versions
                <AssessmentCard
                  contents={[
                    // {
                    //   isDone: true,
                    //   label: 'Inactive Operating Status'
                    // },
                    {
                      isDone: companyData?.carrierAuthority?.oldestValue?.value?.commonAuthorityStatus == 'Active',
                      label: 'Active Authority'
                    }
                    // {
                    //   isDone: true,
                    //   label: 'Active insurance'
                    // },
                    // {
                    //   isDone: true,
                    //   label: 'Inspected past 24 months'
                    // },
                    // {
                    //   isDone: true,
                    //   label: 'Acceptable safety rating'
                    // },
                    // {
                    //   isDone: true,
                    //   label: '8 y/o authority'
                    // }
                  ]}
                />
              )}
              {companyData?.carrierAuthority?.oldestValue?.value && (
                <AuthorityCard
                  contents={[
                    {
                      label: 'Authority',
                      value:
                        companyData?.carrierAuthority?.oldestValue?.value?.authorizedForBroker == 'true'
                          ? 'Broker'
                          : 'Carrier'
                    },
                    {
                      label: 'Broker Authority Status',
                      value: companyData?.carrierAuthority?.oldestValue?.value?.brokerAuthorityStatus
                    },
                    {
                      label: 'Common Authority Status',
                      value: companyData?.carrierAuthority?.oldestValue?.value?.commonAuthorityStatus
                    },
                    {
                      label: 'Contract Authority Status',
                      value: companyData?.carrierAuthority?.oldestValue?.value?.contractAuthorityStatus
                    }
                  ]}
                />
              )}
              {/* <AdditionalInformationCard
              heading={'Inspection'}
              contents={[
                {
                  key: 'inspection-1',
                  label: 'Within the past year',
                  value: '5'
                },
                {
                  key: 'inspection-2',
                  label: 'Total',
                  value: '250'
                }
              ]}
            /> */}
              {/* <AdditionalInformationCard
              heading={'Violations'}
              contents={[
                {
                  key: 'violations-1',
                  label: 'Within the past year',
                  value: '5'
                },
                {
                  key: 'violations-2',
                  label: 'Total',
                  value: '250'
                }
              ]}
            /> */}
              {/* <AdditionalInformationCard
              heading={'Crashes'}
              contents={[
                {
                  key: 'crashes-1',
                  label: 'Within the past year',
                  value: '5'
                },
                {
                  key: 'crashes-2',
                  label: 'Total',
                  value: '250'
                }
              ]}
            /> */}
            </CompanySectionLayout>
          )
        }
      />
      {/* <InspectionMapCard /> */}
    </React.Fragment>
  )
}
