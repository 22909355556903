import { Box, Button, Divider, Heading, HStack, Text, useColorModeValue as mode } from '@chaine/keychaine'
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { ROUTES } from '../_routes/routes-list'
import { useAnalytics } from '../_utils/analytics-context'
import { authService } from '../auth/data'
import { useAuth } from '../auth/data/use-auth'

// Specific workspaces for features
const greatLakesWorkspaceID = '15ac8580-742d-45db-ae04-3bb538928e01'

const UnmemoizedAccountSettingsSideMenu = () => {
  const navigate = useNavigate()
  const { selectedWorkspace, isUserAdminOrOwner } = useAuth()
  const { workspaces } = authService.getWorkspacesByUser()
  const activeWorkspaceName = selectedWorkspace?.workspace?.workspacename
  const selectedWorkspaceID = selectedWorkspace?.workspace?.id
  const { pathname } = useLocation()

  //Check if the user is a chaine internal user or specific workspace
  const { isFeatureEnabled } = useAnalytics()
  const isChaineInternalUser = isFeatureEnabled('chaine-internal-user')
  const workspaceSpecificFeatureFlag = selectedWorkspaceID === greatLakesWorkspaceID

  /** Set colors that use hooks prior to conditional logic */
  const selectedBackgroundColor = mode('gray.100', 'gray.800')
  const selectedTextColor = mode('grayDark', 'gray.100')
  const selectedVerticalLineColor = mode('blue.600', 'blue.300')
  const selectedBorderColor = mode('blue.600', 'blue.300')
  const unselectedTextColor = mode('grayLight', 'gray.300')

  const settingsMenu = [
    {
      title: 'Profile',
      url: `/${ROUTES.ACCOUNT}/${ROUTES.PROFILE}`
    }
  ]

  if (workspaces && workspaces.length > 0)
    settingsMenu.push({
      title: 'Workspaces',
      url: `/${ROUTES.ACCOUNT}/${ROUTES.WORKSPACES}`
    })

  if (selectedWorkspace && activeWorkspaceName && isUserAdminOrOwner(selectedWorkspace.accessType || '')) {
    settingsMenu.push({
      title: 'Workspace',
      url: `/${activeWorkspaceName}/${ROUTES.ACCOUNT}/${ROUTES.WORKSPACE}`
    })
    settingsMenu.push({
      title: 'Members',
      url: `/${activeWorkspaceName}/${ROUTES.ACCOUNT}/${ROUTES.MEMBERS}`
    })
    /** Uncomment when implementing BILLING */
    // settingsMenu.push({
    //   title: 'Billing',
    //   url: `/${activeWorkspaceName}/${ROUTES.ACCOUNT}/${ROUTES.BILLING}`
    // })
    settingsMenu.push({
      title: 'Reports',
      url: `/${activeWorkspaceName}/${ROUTES.ACCOUNT}/${ROUTES.USAGE}`
    })
    settingsMenu.push({
      title: 'Apps',
      url: `/${activeWorkspaceName}/${ROUTES.ACCOUNT}/${ROUTES.APPS}`
    })
    settingsMenu.push({
      title: 'Copilot',
      url: `/${activeWorkspaceName}/${ROUTES.ACCOUNT}/${ROUTES.COPILOT}`
    })
    settingsMenu.push({
      title: 'Developers',
      url: `/${activeWorkspaceName}/${ROUTES.ACCOUNT}/${ROUTES.DEVELOPERS}`
    })
    settingsMenu.push({
      title: 'Logs',
      url: `/${activeWorkspaceName}/${ROUTES.ACCOUNT}/${ROUTES.LOGS}`
    })
    settingsMenu.push({
      title: 'Integrations',
      url: `/${activeWorkspaceName}/${ROUTES.ACCOUNT}/${ROUTES.INTEGRATIONS}`
    })
    settingsMenu.push({
      title: 'Notifications',
      url: `/${activeWorkspaceName}/${ROUTES.ACCOUNT}/${ROUTES.NOTIFICATIONS}`
    })
    isChaineInternalUser || workspaceSpecificFeatureFlag
      ? settingsMenu.push({
          title: 'Voice Bot',
          url: `/${activeWorkspaceName}/${ROUTES.ACCOUNT}/${ROUTES.VOICE_BOT}`
        })
      : null
    isChaineInternalUser || workspaceSpecificFeatureFlag
      ? settingsMenu.push({
          title: 'Voice Bot Reports',
          url: `/${activeWorkspaceName}/${ROUTES.ACCOUNT}/${ROUTES.VOICE_BOT_REPORTS}`
        })
      : null
    settingsMenu.push({
      title: 'Offers Email',
      url: `/${activeWorkspaceName}/${ROUTES.ACCOUNT}/${ROUTES.OFFERS_EMAIL_CONFIGURATION}`
    })
  }
  const billingMenu = [
    {
      title: 'Billing',
      url: ROUTES.BILLING
    },
    {
      title: 'Plans',
      url: ROUTES.BILLING + ROUTES.BILLING_PLAN
    },
    {
      title: 'Invoice',
      url: ROUTES.BILLING + ROUTES.BILLING_INVOICE
    },
    {
      title: 'Cancel plan',
      url: ROUTES.BILLING + ROUTES.BILLING_CANCEL_PLAN
    }
  ]

  const goTo = (route: string) => {
    navigate(route)
  }

  /** Go back after going into a nested menu such as billing */
  const goBack = () => {
    const paths = location.pathname.split('/')
    paths.pop()
    navigate(paths.join('/'))
  }

  const sideMenuList = pathname.includes('billing') ? billingMenu : settingsMenu
  const currentPathTree = pathname.split('/') // i.e. [  'greatlakes-sandbox', 'account', 'developers']
  const currentPath = currentPathTree[currentPathTree.length - 1] // i.e. 'developers'

  return (
    <Box
      w={{ base: 'full', lg: 48 }}
      py={{ base: 2, lg: 10 }}
      position={{ lg: 'fixed' }}
      zIndex={1}
      px={{ base: 2, lg: 4 }}
    >
      <Box>
        <Text color={mode('gray.600', 'gray.300')} ml={{ base: 2, lg: 0 }}>
          ACCOUNT SETTINGS
        </Text>
      </Box>
      {pathname.includes('billing') && (
        <Button mt={2} variant="sideBar" onClick={goBack} display={{ base: 'none', lg: 'block' }}>
          ← Back
        </Button>
      )}
      <Box
        mt={{ base: 2, lg: 8 }}
        ml={{ base: 'auto', lg: 0 }}
        mr={{ base: 'auto', lg: 0 }}
        display={{ base: 'flex' }}
        flexDirection={{ lg: 'column' }}
        justifyContent={{ base: 'space-around' }}
        pb={{ base: 4, lg: 0 }}
        w={{ base: '90%', lg: 'full' }}
        overflowX={{ base: 'auto', lg: 'hidden' }}
      >
        <HStack w={'full'} spacing={{ base: 6, lg: 0 }} mb={3} flexDirection={{ lg: 'column' }}>
          {sideMenuList.map((setting: { title: string; url: string }) => {
            const menuPathTree = setting.url.split('/')
            const menuPath = menuPathTree[menuPathTree.length - 1]

            return currentPath === menuPath ? (
              <HStack
                w={{ base: 'fit-content', lg: 'full' }}
                mt={{ base: 0, lg: 3 }}
                borderRadius={{ base: 0, lg: 'md' }}
                background={selectedBackgroundColor}
                borderColor={selectedBorderColor}
                borderBottomWidth={{ base: 3, lg: 0 }}
                h={8}
                py={2}
                px={0}
                key={setting.title}
              >
                <Box background={selectedVerticalLineColor} h={5} w={0.5} display={{ base: 'none', lg: 'block' }} />
                <Heading size="sm" color={selectedTextColor} pr={{ base: 1 }}>
                  {setting.title}
                </Heading>
              </HStack>
            ) : (
              <HStack
                mt={{ base: 0, lg: 3 }}
                w={{ base: 'fit-content', lg: 'full' }}
                h={8}
                px={2}
                onClick={() => goTo(setting.url)}
                _hover={{
                  cursor: 'pointer'
                }}
                key={setting.title}
              >
                <Text color={unselectedTextColor} h={5}>
                  {setting.title}
                </Text>
              </HStack>
            )
          })}
        </HStack>
      </Box>
      <Divider h={1} w="100%" />
    </Box>
  )
}

export const AccountSettingsSideMenu = React.memo(UnmemoizedAccountSettingsSideMenu)
